import React, { type ReactNode } from 'react';
import Heading from '@atlaskit/heading';
import { Inline } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { BackButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-back-button/src/ui/back-button/index.tsx';
import { ScrollContainer } from '@atlassian/jira-navigation-apps-sidebar-nav4-common/src/ui/scroll-container';
import { MenuList } from '@atlassian/jira-navigation-system/src/v4';
import { AsyncPageSwitcher } from '../../common/ui/page-switcher';
import type { PageName } from '../../common/ui/page-switcher/types';
import messages from './messages';

type Props = {
	children: ReactNode;
	currentPageName: PageName;
};

export const SidebarContent = ({ children, currentPageName }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Inline
				alignBlock="center"
				grow="hug"
				space="space.100"
				testId="navigation-apps-sidebar-nav4-global-settings-common.ui.sidebar-content.header"
			>
				<BackButton />
				<Heading size="small" as="h2">
					{formatMessage(messages.heading)}
				</Heading>
			</Inline>

			<AsyncPageSwitcher currentPageName={currentPageName} />

			<ScrollContainer>
				<MenuList>{children}</MenuList>
			</ScrollContainer>
		</>
	);
};
